import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { StorageKey } from '../models/storage.model';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

const { AUTH_TOKEN, CURRENT_USER_INFO } = StorageKey;

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  token: string;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private storage: StorageService
  ) {
    this.token = storage.read(AUTH_TOKEN);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinner.show();
    return next
      .handle(
        req.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Token ${this.token}`,
          },
        })
      )
      .pipe(
        retry(2),
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.spinner.hide();
            }
          },
          (error: any) => {
            this.spinner.hide();
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                // notify user in case of this error happening
                // this.__notification.error('Error!', 'Something went wrong. Contact System Administrator!');
                if (error.error.detail === 'Invalid token.') {
                  this.storage.remove(AUTH_TOKEN);
                  this.storage.remove(CURRENT_USER_INFO);
                  this.storage.clear();
                  localStorage.clear();
                  window.sessionStorage.clear();
                  this.authService.authenticationState.next(false);
                  this.router.navigate(['hprs']);
                }
              } else if (error.status === 400) {
                // notify user in case of this error 400 happening
                // this.__notification.error('Error!', 'Something went wrong. Contact System Administrator!');
              }
            } else {
              // notify user in case of other errors happening
              // this.__notification.error('Interceptor Error!', 'Something went wrong. Contact System Administrator!');
            }
          }
        )
      );
  }
}
